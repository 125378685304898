import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_USER_OFFERS, ADMIN_USER_OFFERS_MANAGE, ADMIN_USER_OFFER_PRODUCTS_CREATE } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import ViewHeader from 'Components/layout/panel/ViewHeader';
import Editor from 'Components/admin/userOfferProducts/Editor';
import { TAB_USER_OFFERS } from 'Components/pages/admin/UserOffers/component';
import { TAB_PRODUCTS_LIST } from 'Components/pages/admin/UserOffersManage/component';

export default class AdminUserOfferProductsCreate extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    render() {
        const { location, history } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search);

        return (
            <StyledComponent
                className="admin-offer-products-create"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_USER_OFFERS.path, {}, { tab: TAB_USER_OFFERS }),
                        label: 'Zamówienia',
                    }, {
                        to: withVariables(ADMIN_USER_OFFERS_MANAGE.path, { id: queryObject.userOfferId }, { tab: TAB_PRODUCTS_LIST }),
                        label: 'Edytuj zamówienie',
                    }, {
                        to: withVariables(ADMIN_USER_OFFER_PRODUCTS_CREATE.path, {}, { offerId: queryObject.userOfferId }),
                        label: 'Przypisz produkt do zamówienia',
                    }]}
                >
                    <ViewHeader
                        title="Przypisz produkt do zamówienia"
                    />
                    <Editor
                        location={location}
                        history={history}
                        userOfferId={queryObject.userOfferId || undefined}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}